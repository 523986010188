<style lang="less" scoped>
  @import "../../assets/css/variables";
  @import "~cropperjs/dist/cropper.css";

  .content {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .info-content {
    margin: 46px auto 0 auto;
    width: 900px;
    display: flex;
    position: relative;
    .save-button {
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      max-width: 100%;
    }
    .image-loader-wrapper {
      flex: 0 0 300px;
      .header-image-wrapper {
        position: relative;
        margin-top: 24px;
        height: 300px;
        line-height: 300px;
        width: 300px;
        background-color: #f5f5f5;
        overflow: hidden;
        .headerImage {
          height: 180px;
          vertical-align: middle;
          max-width: 100%;
        }
        .image-warning {
          position: absolute;
          padding: 20px;
          line-height: 24px;
          top: 105px;
          z-index: 2;
        }
      }
    }
    .preview-wrapper {
      flex: 1;
      margin-top: 32px;
      padding-left: 30px;
      .tips {
        margin-bottom: 10px;
      }
      .preview-container {
        background-color: #f5f5f5;
        width: 100%;
        height: 300px;
        > .preview-content {
          margin: 0 auto;
          width: 450px;
          .preview {
            margin-top: 60px;
            text-align: center;
            position: relative;
            .img-preview-container {
              margin: 0 20px;
              height: 170px;
              line-height: 170px;
              text-align: center;
              .img-preview {
                overflow: hidden;
                img {
                  width: 100%;
                  vertical-align: top;
                }
              }
            }
            .preview-size {
              // position: absolute;
              width: 100%;
              text-align: center;
              bottom: 0;
            }
            &.preview-lg {
              height: 150px;
              width: 190px;
            }
            &.preview-md {
              height: 150px;
              width: 140px;
              .img-preview {
                position: relative;
                top: 25px;
              }
            }
            &.preview-sm {
              height: 150px;
              width: 90px;
              .img-preview {
                position: relative;
                top: 50px;
              }
            }
          }
        }
      }
    }
  }

</style>

<template :is="view">
  <div class="content">
    <div class="info-content ">
      <form id="headImgfileForm" class="none" method="post" enctype="multipart/form-data">
        <input type="file" id="headImgFile" @change="selectedImageFile" name="upfile" accept="image/*;capture=camera">
      </form>
      <div class="image-loader-wrapper">
        <el-button @click="triggerSelectFile" type="primary">选择图片</el-button>
        <div class="header-image-wrapper text-center">
          <img class="headerImage" :src="fileImageUrl"/>
          <div v-if="fileImageUrl===uploadDefaultImg" class="image-warning">只支持jpg、png、jpeg格式，大小不能超过10M</div>
        </div>
      </div>
      <div class="preview-wrapper">
        <el-button v-if="containImage" class="save-button" @click="toUploadImage" type="primary">　保存　</el-button>
        <div class="tips"> 预览:</div>
        <div class="preview-container">
          <div class="preview-content">
            <div class="preview pull-left preview-lg">
              <div class="img-preview-container ">
                <div class="img-preview">
                  <img :src="defaultImg">
                </div>
              </div>
              <div class="preview-size">
                150px*150px
              </div>
            </div>
            <div class="preview pull-left preview-md ">
              <div class="img-preview-container ">
                <div class="img-preview">
                  <img :src="defaultImg">
                </div>
              </div>
              <div class="preview-size">
                100px*100px
              </div>
            </div>
            <div class="preview pull-left preview-sm ">
              <div class="img-preview-container ">
                <div class="img-preview">
                  <img :src="defaultImg">
                </div>
              </div>
              <div class="preview-size">
                50px*50px


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

  //webpack 会将此转为base64 然后就可以用了，src一个静态本地想对路径图片的时候会被webpack url-loader拦截
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import cookie from '../../utils/http/cookie';
  import {API_ROOT}from '../../config';
  import {updateHeadImage} from '../../services/ucenter';
  import {uploadImageFile} from '../../services/common';
  import {mapGetters, mapActions} from "vuex";
  import {Notification} from "element-ui";
  import {valiEmail} from "../../utils/validator/element";
  import {getFileUrl} from "../../utils/tools/tools";
  import uploadDefaultImg from '../../assets/images/upload-defualt.png';
  import Cropper from 'cropperjs';
  let cropperObj = null;//剪裁图片对象
  export default {
    beforeRouteEnter(to, from, next) {
      next();
    },

    data() {
      return {
        containImage: false,//是否已经有图片被选进
        defaultImg,
        uploadDefaultImg,
        fileImageUrl: uploadDefaultImg//默认1px的透明图片 base64
      }
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
      /**
       * 触发文件选择
       */
      triggerSelectFile(){
        document.querySelector('#headImgFile').click();
      },

      /**
       * Cropperjs用来自定义循环预览的现就这么用着吧
       **/
      each(arr, callback) {
        for (let i = 0; i < arr.length; i++) {
          callback.call(arr, arr[i], i, arr);
        }
        return arr;
      },
      /**
       * 选中
       */
      selectedImageFile($event){
        let files = $event.target.files;
        let file = null;
        let self = this;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if ((file.size / 1024 / 1024 / 10) > 1) { //10M
            Notification.error({
              title: '错误',
              message: '请选择一个小于1M图片'
            });
            return;
          }
        } else {
          Notification.error({
            title: '错误',
            message: '请选择一个图片文件'
          });
          return;
        }
        const image = document.querySelector('.headerImage');
        this.fileImageUrl = getFileUrl('#headImgFile');
        if (cropperObj) {
          // cropperObj.destory();
          cropperObj.replace(getFileUrl('#headImgFile'));
        } else {
          let previews = document.querySelectorAll('.img-preview');
          setTimeout(() => {
            cropperObj = new Cropper(image, {
              aspectRatio: 1 / 1,
              autoCrop: true,
              ready(){
                let cropper = this.cropper;
                cropper.crop();//因为会重复的选择图片，必须关掉auto 然后在ready中调用
                let clone = this.cloneNode();
                clone.className = '';
                clone.style.cssText = (
                  'display: block;' +
                  'width: 100%;' +
                  'min-width: 0;' +
                  'min-height: 0;' +
                  'max-width: none;' +
                  'max-height: none;'
                );
                self.each(previews, function (elem) {
                  elem.innerHTML = '';
                  elem.appendChild(clone.cloneNode());
                });
              },
              crop(e) {
                let data = e.detail;
                self.containImage = true;
                let cropper = this.cropper;
                let imageData = cropper.getImageData();
                let previewAspectRatio = data.width / data.height;
                self.each(previews, function (elem) {
                  let previewImage = elem.getElementsByTagName('img').item(0);
                  let previewWidth = elem.offsetWidth;
                  let previewHeight = previewWidth / previewAspectRatio;
                  let imageScaledRatio = data.width / previewWidth;
                  elem.style.height = previewHeight + 'px';
                  previewImage.style.width = imageData.naturalWidth / imageScaledRatio + 'px';
                  previewImage.style.height = imageData.naturalHeight / imageScaledRatio + 'px';
                  previewImage.style.marginLeft = -data.x / imageScaledRatio + 'px';
                  previewImage.style.marginTop = -data.y / imageScaledRatio + 'px';
                });
              }
            });
          }, 150);
        }
      },

      /**
       * 更新头像的
       */
      async updateUserinfo(userInfo) {
        const userinfoData = await updateHeadImage(userInfo);
        if (userinfoData && userinfoData.errorCode === 0
          && userinfoData.data && userinfoData.data.result) {
          Notification.success({
            title: '成功',
            message: '您的头像更新成功'
          });
          //this.userInfo.imageUrl = imagePath;
          //cookie('userInfo', JSON.stringify(userInfo));
          this.$store.dispatch('refreshUserInfo', this.userInfo);

        }
      },
      /**
       * [提交上传更新自己的照片]
       * @return {[type]} [description]
       */
      toUploadImage() {
        // const croppedImage = cropperObj.getCroppedCanvas().toDataURL('image/jpeg');
        cropperObj.getCroppedCanvas().toBlob(this.uploadImage);
      },
      /**
       * [提交上传更新自己的照片]
       * @param croppedImage File
       * @return {[type]} [description]
       */
      async uploadImage(croppedImage) {
        croppedImage.lastModifiedDate = new Date();
        croppedImage.name = 'headerImage.png';
        const uploadData = await uploadImageFile(croppedImage);
        if (uploadData && uploadData.errorCode === 0
          && uploadData.data) {
          if (uploadData.data.result) {
            //const user = {imageUrl: uploadData.data.fpicurl};
            let user = Object.assign({}, this.user);
            user.image = uploadData.data.fpicurl;
            // console.log(this.userInfo)
            this.$store.dispatch('refreshUserInfo',user);
            this.updateUserinfo({
              id: user.id,
              name: user.name,
              imageurl: user.image
            }).then(() => {
              Notification.success({
                title: '成功',
                message: '修改头像成功'
              })
            })
          } else {
            Notification.error({
              title: '失败',
              message: uploadData.data.message
            });
          }
          /* Notification.success({
           title: '成功',
           message: '修改自己的图片成功'
           });*/
          //this.userInfo.imageUrl==uploadData.data.fpicurl;
          //cookie('userInfo', false);
          //this.$store.dispatch('refreshUserInfo', {});
          //window.router.push('/login')
        }
      }
    },
    created(){
      this.userInfo = Object.assign({}, this.user);
      /*$('.userinfo-image').on('click touchstart', function (e) {
       e.stopPropagation();
       $('#headImgfileForm input').trigger('click');
       });*/
    },
    watch: {
      user(newVal, oldVal){
        if (newVal != oldVal) {
          this.userInfo = Object.assign({}, this.user);
        }
      }
    }
  }

</script>
